// src/components/Layout.js
import React from "react";
import NavBar from "../NavBar";
import EnquiryForm from "../EnquiryForm";
import Footer from "../Footer";
import WhatsappBtn from "../WhatsappBtn";
import Breadcrumb from "../Breadcrumb";
import Preloader from "../Preloader";
import Authorized from "../Authorized";

const Layout = ({ children }) => {
  return (
    <>
      <Preloader />
      <NavBar />
      <Breadcrumb />
      <EnquiryForm />
      <WhatsappBtn />
      <main>{children}</main>
      <Authorized/>
      <Footer />
    </>
  );
};

export default Layout;
