import React from "react";
import { Link } from "react-router-dom";
import ContactForm from "../ContactForm";

const BlogPageLayout = ({ children }) => {
  return (
    <>
      <section className="flex flex-col md:flex-row-reverse mx-5">
        <div className="flex flex-col md:flex-row pt-5 gap-y-10 md:gap-x-10">
          {/* blog sideForm */}
          <aside className="w-full md:w-1/3 lg:w-1/3">
            <div className="sticky top-24 p-4 bg-gray-100 rounded-lg shadow-md">
              <div className="blog_widget">
                <div
                  className="gf_browser_chrome gform_wrapper gform_legacy_markup_wrapper gform-theme--no-framework gw-horizontal-form_wrapper"
                  data-form-theme="legacy"
                  data-form-index={0}
                  id="gform_wrapper_85"
                >
                  {/* <form
                    className="register-form position-relative z-5"
                    id="myForm"
                  >
                    <h3 className="mb-5 h5 text-color-navy font-medium">
                      Fill out the form and we'll be in touch as soon as
                      possible.
                    </h3>
                    <div className="flex flex-wrap -mx-3">
                      <div className="w-full sm:w-1/2 px-3 mb-3">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control w-full"
                            placeholder="Full Name"
                            aria-label="fullname"
                            required=""
                            id="fullname"
                          />
                        </div>
                      </div>
                      <div className="w-full sm:w-1/2 px-3 mb-3">
                        <div className="input-group">
                          <input
                            type="tel"
                            maxLength={10}
                            className="form-control w-full"
                            placeholder="Number"
                            aria-label="number"
                            required=""
                            id="number"
                            pattern="[0-9]{10}"
                            title="Please enter a 10-digit phone number"
                          />
                        </div>
                      </div>
                      <div className="w-full sm:w-1/2 px-3 mb-3">
                        <div className="input-group">
                          <input
                            type="email"
                            className="form-control w-full"
                            placeholder="Email"
                            aria-label="email"
                            required=""
                            id="email"
                          />
                        </div>
                      </div>
                      <div className="w-full sm:w-1/2 px-3 mb-3">
                        <div className="input-group">
                          <select
                            className="form-control w-full"
                            required=""
                            id="course"
                          >
                            <option value="">-Choose Course-</option>
                            <option value="Front-End Course">Front-End Course</option>
                            <option value="Back-End Course">Back-End Course</option>
                            <option value="React JS Course">React JS Course</option>
                            <option value="MERN Stack Course">MERN Stack Course</option>
                            <option value="Data Analytics Course">Data Analytics Course</option>
                            <option value="Digital Marketing Course">Digital Marketing Course</option>
                            <option value="Data Science">Data Science</option>
                            <option value="DSA">DSA</option>
                            <option value="Java Programming">Java Programming</option>
                            <option value="Java Fullstack">Java Fullstack</option>
                            <option value="Python Programming">Python Programming</option>
                            <option value="Python Fullstack">Python Fullstack</option>
                          </select>
                        </div>
                      </div>
                      <div className="w-full sm:w-1/2 px-3 mb-3">
                        <div className="input-group">
                          <select
                            className="form-control w-full"
                            required=""
                            id="city"
                          >
                            <option>-Select City-</option>
                            <option value="Indore">Indore</option>
                            <option value="Ujjain">Ujjain</option>
                            <option value="Bhopal">Bhopal</option>
                            <option value="Dewas">Dewas</option>
                            <option value="Khargone">Khargone</option>
                            <option value="Barwaha">Barwaha</option>
                            <option value="Dhar">Dhar</option>
                            <option value="Dhamnod">Dhamnod</option>
                            <option value="Maheshwar">Maheshwar</option>
                            <option value="Sanwer">Sanwer</option>
                            <option value="Depalpur">Depalpur</option>
                            <option value="Hatod">Hatod</option>
                            <option value="Betma">Betma</option>
                            <option value="Manpur">Manpur</option>
                            <option value="Sanawad">Sanawad</option>
                            <option value="Vadodara">Vadodara</option>
                            <option value="Jabalpur">Jabalpur</option>
                            <option value="Sagar">Sagar</option>
                            <option value="Balaghat">Balaghat</option>
                            <option value="Delhi">Delhi</option>
                            <option value="Mumbai">Mumbai</option>
                            <option value="Pune">Pune</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </div>
                      <div className="w-full sm:w-1/2 px-3 mb-3">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control w-full"
                            placeholder="College"
                            aria-label="college"
                            required=""
                            id="college"
                          />
                        </div>
                      </div>
                      <div className="w-full px-3 mb-3">
                        <div className="input-group">
                          <textarea
                            className="form-control w-full"
                            placeholder="Tell us more about your query."
                            style={{ height: 120 }}
                            id="message"
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      <div className="w-full px-3">
                        <button
                          type="submit"
                          className="btn btn-primary mt-4 w-full"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form> */}
                  <ContactForm/>
                </div>
              </div>
            </div>
          </aside>
          {/* blog main content */}
          <main className="w-full md:w-2/3 lg:w-2/3">{children}</main>
        </div>
      </section>

      <footer className="w-full bg-gray-200 h-auto py-4 fixed bottom-0 z-40 flex flex-col md:flex-row items-center justify-center gap-4 md:gap-10">
        <Link
          className="border bg-slate-900 text-white px-4 py-2 font-bold text-base md:text-lg rounded-xl hover:scale-105 transition-transform duration-300"
          to="tel:+91-7674040233"
        >
          Make a call
        </Link>
        <Link
          className="border-2 bg-white border-slate-900 text-slate-900 px-4 py-2 font-bold text-base md:text-lg rounded-xl hover:scale-105 transition-transform duration-300"
          to="mailto:hr@mindcoders.in"
        >
          Write a mail
        </Link>
      </footer>
    </>
  );
};

export default BlogPageLayout;
