import React, { useRef } from "react";
import CourseCard from "../components/cards/CourseCard";
import { course } from "../data/courseCardData";
import BlogCard from "../components/cards/BlogCard";
import Carousel from "../components/Carousel";
import Benifits from "../components/Benifits";
import { baseUrl } from "../helpers/baseUrl";
import { Autoplay, EffectCoverflow } from "swiper/modules";
// import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import ContactForm from "../components/ContactForm";
import { TechMarquee } from "../components/Marquee";
import { VerticalReviews } from "../components/VirticalReviews";

const HomePage = () => {
  let imgPath = baseUrl();
  const swiperRef = useRef(null);

  return (
    <>
      <Carousel />

      {/* Intro start */}
      <section className="text-gray-800 -mt-4 pt-3 body-font">
        <div className=" bg-gray-200 px-5 py-3">
          <div className="flex flex-wrap -m-4">
            {/* Card 1 */}
            <div className="p-4 md:w-1/3 w-full flex flex-col items-start">
              <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-gray-800 text-indigo-400 mb-4 flex-shrink-0  animate__animated animate__backInDown">
                <i className="fa-solid fa-wave-square"></i>
              </div>
              <div className="flex-grow">
                <h2 className="text-color-navy font-bold text-lg title-font mb-2">
                  Classroom Training
                </h2>
                <p className="leading-relaxed text-base">
                  Best classroom in indore where we train upcoming Industry
                  experts
                </p>
              </div>
            </div>
            {/* Card 2 */}
            <div className="p-4 md:w-1/3 w-full flex flex-col items-start">
              <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-gray-800 text-indigo-400 mb-4 flex-shrink-0  animate__animated animate__backInDown">
                <i className="fa-solid fa-scissors"></i>
              </div>
              <div className="flex-grow">
                <h2 className="text-color-navy font-bold text-lg title-font mb-2">
                  Live Project Training
                </h2>
                <p className="leading-relaxed text-base">
                  Mindcoder's Live Projects make you ready for Vibrant Business
                  Hub of Indore
                </p>
                {/* <a className="mt-3 text-indigo-400 inline-flex items-center">
            Learn More
            <i className="fa-solid fa-arrow-right ms-2"></i>
          </a> */}
              </div>
            </div>
            {/* Card 3 */}
            <div className="p-4 md:w-1/3 w-full flex flex-col items-start">
              <div className="w-12 h-12 inline-flex items-center justify-center rounded-full bg-gray-800 text-indigo-400 mb-4 flex-shrink-0  animate__animated animate__backInDown">
                <i className="fa-regular fa-user"></i>
              </div>
              <div className="flex-grow">
                <h2 className="text-color-navy font-bold text-lg title-font mb-2">
                  Internship Program
                </h2>
                <p className="leading-relaxed text-base">
                  Our Interships Make you ready for Challenging roles, We treat
                  you like Industry Professional and not just like student
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Intro end */}

      {/* courses start */}
      <section className="services-icon mt-8 pb-5">
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          className="container mx-auto px-4"
        >
          <div className="text-center mb-8">
            <h2 className="text-3xl font-bold">
              Most Popular <span className="text-info">Training Courses</span>
            </h2>
            <p className="mt-4 text-lg">
              Aim to offer something really that adds values to your career from
              any professional background and any stage in their careers.
            </p>
          </div>
          <div>
            <Swiper
              effect={"coverflow"}
              centeredSlides={true}
              slidesPerView={"auto"}
              loop={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
              coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}
              pagination={true}
              modules={[EffectCoverflow, Autoplay]}
              className="mySwiper"
            >
              {course.map((info, index) => (
                <SwiperSlide
                  key={index}
                  className="w-full max-w-[300px]"
                  onMouseEnter={() => swiperRef.current?.autoplay.pause()}
                  onMouseLeave={() => swiperRef.current?.autoplay.resume()}
                >
                  <CourseCard
                    title={info.title}
                    img={info.banner}
                    url={info.url}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
      {/* courses end */}
      
      {/*join us start*/}
      <section className="ourgrowthSec wow animated ptb-80 fadeInUp animated courses">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="expContent expContent2">
                <div>
                  <h2 className="h1 font-weight-bold">
                    Why Mind <span className="text-info">Coders ?</span>{" "}
                  </h2>
                  <h6 className="mb-4">
                    100% Career Oriented Courses. 100% Practical Training
                  </h6>
                  {/* <h5></h5> */}
                </div>
              </div>
            </div>
          </div>
          <ul className="row join-list">
            <li className="col-lg-2 col-md-3 col-sm-4">
              <div className="ourgrowthImg" data-aos="flip-left">
                <img
                  src={`${imgPath}/assets/img/home-join/join1.webp`}
                  alt=""
                />
                <span className="s12 mt-3 rs-count">2000+</span>
              </div>
              <p>CANDIDATES TRAINED</p>
            </li>
            {/* Candidates trained */}
            <li className="col-lg-2 col-md-3 col-sm-4">
              <div className="ourgrowthImg" data-aos="flip-left">
                <img
                  alt=""
                  src={`${imgPath}/assets/img/home-join/join2.webp`}
                />
                <span className="s12 mt-3">100% Placed</span>
              </div>
              <p>PLACEMENTS</p>
            </li>
            <li className="col-lg-2 col-md-3 col-sm-4">
              <div className="ourgrowthImg" data-aos="flip-left">
                <img
                  alt=""
                  src={`${imgPath}/assets/img/home-join/join3.webp`}
                />
                <span className="s12 mt-3 rs-count">30+</span>
              </div>
              <p>CORPORATE TRAINING</p>
            </li>
            <li className="col-lg-2 col-md-3 col-sm-4">
              <div className="ourgrowthImg" data-aos="flip-left">
                <img
                  alt=""
                  src={`${imgPath}/assets/img/home-join/join4.webp`}
                />
                <span className="s12 mt-3 rs-count">400+</span>
              </div>
              <p>SEMINARS</p>
            </li>
            <li className="col-lg-2 col-md-3 col-sm-4">
              <div className="ourgrowthImg" data-aos="flip-left">
                <img alt="" src="./assets/img/home-join/join5.webp" />
                <span className="s12 mt-3 rs-count">500+</span>
              </div>
              <p>Internship</p>
            </li>
            <li className="col-lg-2 col-md-3 col-sm-4">
              <div className="ourgrowthImg" data-aos="flip-left">
                <img alt="" src="./assets/img/home-join/join6.webp" />
                <span className="s12 mt-3 rs-count">400+</span>
              </div>
              <p>Workshops</p>
            </li>
          </ul>
        </div>
      </section>
      {/*join us end*/}

      {/* Benifits start */}
      <section className="container">
        <Benifits />
      </section>
      {/* Benifits end */}

      <section className="py-3 my-5">
        <h1 className="h1 text-color-navy text-center">Tech stack we Offer</h1>
        <TechMarquee />
      </section>

      {/*blog section start*/}
      <section className="home-blog-section pb-120">
        <div className="container" data-aos="fade-up" data-aos-duration="1000">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-12">
              <div className="section-heading text-center">
                <h1 className="text-color-navy h1 font-weight-bold">
                  Our Latest Blog
                </h1>
              </div>
            </div>
          </div>
          <BlogCard />
        </div>
      </section>
      {/*blog section end*/}

      {/* enquiry Form start */}
      <section className="contact-us ptb-120 position-relative">
        <div className="container ">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
            <div>
              <div
                className="section-heading aos-init aos-animate"
                data-aos="fade-up"
              >
                <h4 className="h5 text-info">Have any queries?</h4>
                <h2 className="h3 text-color-navy">Get in Touch Today!</h2>
              </div>
              <div className="row justify-content-between pb-5">
                <div
                  className="col-sm-6 mb-4 mb-md-0 mb-lg-0 aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay={50}
                >
                  <div className="icon-box d-inline-block rounded-circle bg-primary-soft">
                    <i className="fas fa-phone fa-2x text-primary" />
                  </div>
                  <div className="contact-info">
                    <h5 className="h5 text-color-navy">Call Us</h5>
                    <p>
                      Questions about our course or pricing? Call for support
                    </p>
                    <a
                      href="tel:7674040233"
                      className="read-more-link text-decoration-none"
                    >
                      <i className="fas fa-phone me-2" />
                      7674040233
                    </a>{" "}
                    <br />
                    <a
                      href="tel:7389640233"
                      className="read-more-link text-decoration-none"
                    >
                      <i className="fas fa-phone me-2" />
                      7389640233
                    </a>
                  </div>
                </div>
                <div
                  className="col-sm-6 aos-init aos-animate"
                  data-aos="fade-up"
                  data-aos-delay={100}
                >
                  <div className="icon-box d-inline-block rounded-circle bg-danger-soft">
                    <i className="fas fa-headset fa-2x text-danger" />
                  </div>
                  <div className="contact-info">
                    <h5 className="h5 text-color-navy">Chat Us</h5>
                    <p>
                      Our support will help you from <br />
                      <strong>9am to 6pm IST.</strong>
                    </p>
                    <a
                      href="https://api.whatsapp.com/send?phone=7674040233"
                      target="_blank"
                      className="read-more-link text-decoration-none"
                      rel="noreferrer"
                    >
                      <i className="fas fa-comment me-2" /> Send Message
                    </a>{" "}
                    <br />
                    <a
                      href="mailto:hr@mindcoders.in"
                      target="_blank"
                      className="read-more-link text-decoration-none"
                      rel="noreferrer"
                    >
                      <i className="fa-solid fa-envelope me-2" /> Send Mail
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="rounded-custom aos-init aos-animate"
              data-aos="fade-up"
              data-aos-delay={150}
            >
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
      {/* enquiry Form end */}

      {/* Testimonial  start */}
      <section className="testimonial-section pt-12 pb-12">
        <div
          data-aos="fade-up"
          data-aos-duration="1000"
          className="container mx-auto px-4"
        >
          <div className="text-center mb-12">
            <h4 className="text-info text-2xl font-bold md:text-3xl">
              Testimonial
            </h4>
            <h2 className="text-3xl md:text-4xl font-semibold text-color-navy">
              What’s Student's Say
            </h2>
            <p className="mt-4 max-w-2xl mx-auto">
              We are incredibly grateful for the positive testimonials and
              reviews we've received from our valued students. Your kind words
              inspire and motivate us to continually strive for excellence.
            </p>
          </div>
          <VerticalReviews />
        </div>
      </section>
      {/* Testimonial  end */}
    </>
  );
};

export default HomePage;
