import React from "react";

const TermsAndConditions = () => {
  return (
    <section className="mt-5">
      <div className="container ">
        <div className="row overflow-x-hidden align-items-center justify-content-lg-between justify-content-center gap-.1">
          <div className="col-lg-12 col-md-12 col-xl-12 col-sm-12">
            <div className="feature-content-wrap">
              <div className="section-heading ">
                <h2
                  className="pb-2 h2"
                  data-aos="fade-right"
                  data-aos-anchor="#example-anchor"
                  data-aos-offset={500}
                  data-aos-duration={1000}
                >
                  Terms and Conditions for{" "}
                  <span className="text-info"> MindCoders</span>
                </h2>
                <p
                  data-aos="fade-left"
                  data-aos-anchor="#example-anchor"
                  data-aos-offset={500}
                  data-aos-duration={1000}
                >
                  Welcome to MindCoders! These terms and conditions outline the
                  rules and regulations for the use of MindCoders' Website,
                  located at www.mindcoders.com. By accessing this website, we
                  assume you accept these terms and conditions. Do not continue
                  to use MindCoders if you do not agree to take all of the terms
                  and conditions stated on this page.
                </p>
                <h4 className="h4" data-aos="fade-right">
                  1. General Information
                </h4>
                <p data-aos="fade-left">
                  MindCoders provides IT and non-IT courses through its online
                  platform. These courses are designed to enhance skills and
                  knowledge in various domains. By enrolling in our courses, you
                  agree to abide by these terms and conditions.{" "}
                </p>
                <h4 className="h4" data-aos="fade-right">
                  2. Log Files{" "}
                </h4>
                <p data-aos="fade-left">
                  Like many other websites, MindCoders uses log files. These
                  files log visitors to the site - a standard procedure for
                  hosting companies and a part of hosting services' analytics.
                  The information collected by log files includes internet
                  protocol (IP) addresses, browser type, Internet Service
                  Provider (ISP), date and time stamp, referring/exit pages, and
                  possibly the number of clicks. This information is used to
                  analyze trends, administer the site, track user's movement on
                  the website, and gather demographic information.{" "}
                </p>
                <h4 className="h4" data-aos="fade-right">
                  3. Privacy Policy
                </h4>
                <p data-aos="fade-left">
                  Your privacy is important to us. Our Privacy Policy outlines
                  how we collect, use, and safeguard your personal information.
                  By using MindCoders, you consent to the data practices
                  described in our Privacy Policy.
                </p>
                <h4 className="h4" data-aos="fade-right">
                  4. Third Party Policy{" "}
                </h4>
                <p data-aos="fade-left">
                  MindCoders may contain links to third-party websites or
                  services that are not owned or controlled by us. We have no
                  control over, and assume no responsibility for, the content,
                  privacy policies, or practices of any third-party websites or
                  services. You further acknowledge and agree that MindCoders
                  shall not be responsible or liable, directly or indirectly,
                  for any damage or loss caused or alleged to be caused by or in
                  connection with the use of or reliance on any such content,
                  goods, or services available on or through any such websites
                  or services.
                </p>
                <h4 className="h4" data-aos="fade-right">
                  {" "}
                  5. Student Information{" "}
                </h4>
                <p data-aos="fade-left">
                  When you enroll in courses provided by MindCoders, you agree
                  to provide accurate and complete information. You also agree
                  to maintain the confidentiality of your account information
                  and not to share it with any third party.
                </p>
                <h4 className="h4" data-aos="fade-right">
                  6. Online Privacy{" "}
                </h4>
                <p data-aos="fade-left">
                  MindCoders respects the privacy of its users. We use cookies
                  and other tracking technologies to improve your browsing
                  experience and to analyze website traffic. By using
                  MindCoders, you consent to the use of cookies and tracking
                  technologies as described in our Privacy Policy.
                </p>
                <h3 className="h3" data-aos="fade-right">
                  {" "}
                  <span className="text-info">Changes</span> to Terms{" "}
                  <span className="text-info">and</span> Conditions
                </h3>
                <h4 className="mt-4 h4" data-aos="fade-right">
                  Agreement{" "}
                </h4>
                <p data-aos="fade-left">
                  By accessing or using MindCoders, you acknowledge that you
                  have read, understood, and agree to be bound by these terms
                  and conditions. If you do not agree to these terms and
                  conditions, you may not access or use MindCoders.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermsAndConditions;
