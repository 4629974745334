
export function baseUrl() {
    let url = window.location.origin;
    return url;
}

/**
 * Extracts the last segment of the URL path.
 * @returns {string} The last segment of the URL path.
 */
export const getLastPathSegment = () => {
    const url = window.location.pathname;
    const parts = url.split("/");
    return parts[parts.length - 1];
  };