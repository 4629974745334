import React from "react";
import { Link } from "react-router-dom";
// import Path from "../routes/Path";
import Benifits from "../components/Benifits";
import { baseUrl } from "../helpers/baseUrl";
import { course } from "../data/courseCardData";

const CoursePage = () => {
  return (
    <>
      {/* courses start */}
      <section className="services-icon pt-5 pb-5 courses">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-heading text-center">
                <h2 className="courses-heading h2">
                  Most Popular{" "}
                  <span className="text-info">Training Courses</span>{" "}
                </h2>
                <p>
                  Aim to offer something really that adds values to your career
                  from any professional background and any stage in their
                  careers.
                </p>
              </div>
            </div>
          </div>
          <div className="grid gap-10 justify-center items-center grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
            {course.map((course, index) => (
              <Link
                data-aos="fade-up"
                data-aos-duration="1000"
                className="w-fit"
                key={index}
                to={course.url}
              >
                {course.title === "Master DSA with C & C++" && (
                  <>
                    <h1 className="text-red-500 animate-bounce">New</h1>
                  </>
                )}
                {course.title === "Unlock DSA with C++ Add on Fullstack" && (
                  <>
                    <h1 className="text-red-500 animate-bounce">New</h1>
                  </>
                )}
                <div className="course-card mb-5">
                  <div className="course-card-details">
                    <div className="course-card-img">
                      <img
                        src={`${baseUrl()}/${course.image}`}
                        alt="img"
                        loading="lazy"
                      />
                    </div>
                    <p className="text-xl font-bold"> → {course.title}</p>
                    <p className="text-body line-clamp-2">
                      {course.description}
                    </p>
                  </div>
                  <Link
                    className="course-card-button text-center"
                    to={course.url}
                  >
                    More Info
                  </Link>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
      {/* courses end */}

      <section className="services-icon container pt-5 pb-5">
        <Benifits />
      </section>
    </>
  );
};

export default CoursePage;
