// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* body {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
} */

body {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: large;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0; 
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAGA;;;;GAIG;;AAEH;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;AAClB;AACA;;IAII,gBAAgB;IAChB,SAAS;AACb","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap\");\n@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');\n\n/* body {\n  font-family: \"Inter\", sans-serif;\n  font-optical-sizing: auto;\n  font-style: normal;\n} */\n\nbody {\n  font-family: \"Lato\", sans-serif;\n  font-weight: 400;\n  font-style: normal;\n  font-size: large;\n}\ninput[type=number]::-webkit-inner-spin-button, \ninput[type=number]::-webkit-outer-spin-button { \n    -webkit-appearance: none;\n    -moz-appearance: none;\n    appearance: none;\n    margin: 0; \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
