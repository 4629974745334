import { workshopImg } from "../data/workshopImgs.js";
import BlurFade from "./magicui/image-grid.tsx";

export function WorkshopGrid() {
  return (
    <section id="photos">
      <div className="columns-2 gap-4 sm:columns-3">
        {workshopImg.map((imageUrl, idx) => (
          <BlurFade key={imageUrl} delay={0.25 + idx * 0.05} inView>
            <img
              className="mb-4 size-full rounded-lg object-contain"
              src={imageUrl}
              alt={`Random stock img ${idx + 1}`}
              loading="lazy"
            />
          </BlurFade>
        ))}
      </div>
    </section>
  );
}
