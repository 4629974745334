// src/routes/NotFound.js
import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
    return (
        <div className="flex flex-col items-center justify-center pt-5 px-4">
            <div className='text-center'>
            <h1 className="text-6xl font-bold text-gray-900 mb-4">404</h1>
            <p className="text-2xl font-semibold text-gray-700 mb-6">Oops! Page not found</p>
            <svg
              className="w-64 h-64 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div className="text-center">
            <div className="max-w-md mx-auto mb-8">
              <p className="text-gray-500">
                The page you're looking for doesn't exist or has been moved. Don't worry, you can find your way back home.
              </p>
            </div>
              <Link className='bg-blue-950 text-white px-3 py-2 rounded-lg hover:scale-105 hover:bg-blue-800 transition-all' to={"/"}>
                Go back home
              </Link>
          </div>
          
        </div>
      )
};

export default NotFoundPage;
