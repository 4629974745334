import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "aos/dist/aos.css";
import "swiper/swiper-bundle.css";
import "react-loading-skeleton/dist/skeleton.css";
import "animate.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import BlogContextProvider from "./context/blog/BlogContextProvider";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BlogContextProvider>
        <BrowserRouter>
          <App />
          <Toaster />
        </BrowserRouter>
      </BlogContextProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
