export const carouselItems = [
  {
    imgSrc: "assets/img/learners of mindcoders/1.png",
    name: "Ayushi Raghuvanshi",
    position: "BDE Intern",
  },
  {
    imgSrc: "assets/img/learners of mindcoders/2.png",
    name: "Donald Nassey",
    position: "Digital Marketing Intern",
  },
  {
    imgSrc: "assets/img/learners of mindcoders/3.png",
    name: "Rajnandini Rathore",
    position: "HR Intern",
  },
  {
    imgSrc: "assets/img/learners of mindcoders/4.png",
    name: "Hardik Sahu",
    position: "Digital Marketing Intern",
  },
  {
    imgSrc: "assets/img/learners of mindcoders/6.png",
    name: "Aashray Thakur",
    position: "Mern Stack Developer Intern",
  },
  {
    imgSrc: "assets/img/learners of mindcoders/7.png",
    name: "Akshay Malviya",
    position: "Software Developer Intern",
  },
  {
    imgSrc: "assets/img/learners of mindcoders/8.png",
    name: "Priya Tomar",
    position: "HR Intern",
  },
  {
    imgSrc: "assets/img/learners of mindcoders/9.png",
    name: "Atharv Vyas",
    position: "Mern Stack Developer",
  },
  {
    imgSrc: "assets/img/learners of mindcoders/10.png",
    name: "Tanya Gayakwad",
    position: "HR Intern",
  },
]
