import React from "react";
import Tabs from "../../components/Tabs";
import { digitalMarketingData } from "../../data/courseTabData";
import { digitalMarketing } from "../../data/courseCurriculamData";
import { Icons } from "../../data/animatedBeamIconsData";
import { AnimatedBeamComponent } from "../../components/AnimatedBeamComponent.tsx";
import { metaData } from "../../data/metaData.js";
import { Helmet } from "react-helmet";

const DigitalMarketingPage = () => {
  const currentMetaData = metaData.find((meta) =>
    meta.title.includes("Digital Marketing")
  );
  return (
    <>
      <Helmet>
        <title>{currentMetaData.title}</title>
        <meta name="title" content={currentMetaData.metaTitle} />
        <meta name="description" content={currentMetaData.metaDescription} />
        <meta name="keywords" content={currentMetaData.metaKeywords} />
      </Helmet>
      {/*hero section start*/}
      <section className="d-flex align-items-center position-relative">
        <div className="container">
          <div className="row mt-5 justify-content-between align-items-center">
            <div className="col-lg-6">
              <div className="hero-content-wrap d-flex gap-2">
                <div className="divider">
                  <span className="saperater" />
                </div>
                <div>
                  <h1 className="h1 course-heading">
                  Digital Marketing Classes in Indore
                  </h1>
                  <p className="course-overview">
                  At Mindcoders, We offer you the Industry-focused Best Digital Marketing Course in indore with Certifications and provide you Internship and Placements support. Located in the vibrant business hub of Indore we provide you training with industry experts who understands the unique challenges and oppurtunities in indore. Our Course includes SEO, Meta and Google ads, SEM, SMM, Email Marketing etc to gain Hands-on experience in Indore's Booming Market. Get benefitted from our Placement assistance in the city's expanding digital sector. Our curriculum is designed with input from experts familiar with the unique business environment around IIM Indore.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hero-content-wrap">
                <AnimatedBeamComponent icons={Icons} />
              </div>
            </div>
          </div>
          <div className="flex flex-col ">
            <main className="flex-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4 sm:p-6">
              {digitalMarketing.map((course, index) => (
                <div
                  key={index}
                  className="bg-slate-100 rounded-lg shadow-sm p-4 flex flex-col"
                >
                  <h3 className="text-lg font-semibold mb-2">
                    {course.name || course.heading}
                  </h3>
                  <p className="text-muted-foreground text-sm">
                    <ul>
                      {course.topics.map((topic, i) => (
                        <li className="list-disc" key={i}>
                          {topic}
                        </li>
                      ))}
                    </ul>
                  </p>
                </div>
              ))}
            </main>
          </div>
        </div>
      </section>
      {/*hero section end*/}
      <Tabs content={digitalMarketingData} />
    </>
  );
};

export default DigitalMarketingPage;
