import React from "react";
import ContactForm from "../components/ContactForm";

const ContactUsPage = () => {
  return (
    <>
  <section className="bg-gray-100 py-20 position-relative">
    <div className="container mx-auto px-4">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div>
          <div className="section-heading" data-aos="fade-up">
            <h4 className="h5 text-info">Have any queries?</h4>
            <h2 className="h3 text-color-navy">Get in Touch Today!</h2>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 pb-2">
            <div data-aos="fade-up" data-aos-duration="1000">
              <div className="icon-box d-inline-block rounded-circle bg-primary-soft p-2">
                <i className="fas fa-phone fa-2x text-primary" />
              </div>
              <div className="contact-info mt-4">
                <h5 className="h5 text-color-navy">Call Us</h5>
                <p className="text-gray-600">
                  Questions about our course or pricing? Call for support.
                </p>
                <a
                  href="tel:7674040233"
                  className="read-more-link text-decoration-none block mt-2"
                >
                  <i className="fas fa-phone me-2" />
                  7674040233
                </a>
                <a
                  href="tel:7389640233"
                  className="read-more-link text-decoration-none block mt-2"
                >
                  <i className="fas fa-phone me-2" />
                  7389640233
                </a>
              </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="1300">
              <div className="icon-box d-inline-block rounded-circle bg-danger-soft p-2">
                <i className="fas fa-headset fa-2x text-danger" />
              </div>
              <div className="contact-info mt-4">
                <h5 className="h5 text-color-navy">Chat Us</h5>
                <p className="text-gray-600">
                  Our support will help you from <br />
                  <strong>9am to 6pm IST.</strong>
                </p>
                <a
                  href="https://api.whatsapp.com/send?phone=7674040233"
                  target="_blank"
                  className="read-more-link text-decoration-none block mt-2"
                  rel="noreferrer"
                >
                  <i className="fas fa-comment me-2" /> Send Message
                </a>
                <a
                  href="mailto:hr@mindcoders.in"
                  target="_blank"
                  className="read-more-link text-decoration-none block mt-2"
                  rel="noreferrer"
                >
                  <i className="fa-solid fa-envelope me-2" /> Send Mail
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="rounded-custom"
          data-aos="fade-up" data-aos-duration="1000"
        >
          <ContactForm />
        </div>
      </div>
    </div>
  </section>

  {/* Location map start */}
  <section data-aos="fade-up" data-aos-duration="1000" className="container mt-5 text-center">
    <h1 className="mb-4 h1">
      Office <span className="text-info">Location</span>
    </h1>
    <div className="map-container w-full h-64 sm:h-96">
      <iframe
        className="w-full h-full"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3681.036198962446!2d75.86122647484132!3d22.689696328669307!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fcfaa339576f%3A0x2ddd6cbfd8fc65f0!2sPearl%20Business%20Park!5e0!3m2!1sen!2sin!4v1700129787276!5m2!1sen!2sin"
        allowFullScreen=""
        loading="lazy"
        title="map"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  </section>
  {/* Location map end */}
</>

  );
};

export default ContactUsPage;
