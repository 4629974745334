import React from "react";
import { Link } from "react-router-dom";

const WhatsappBtn = () => {
  return (
    <>
      <Link
        to="https://api.whatsapp.com/send?phone=7674040233"
        className="float"
        target="_blank"
      >
        <i className="fa-brands fa-whatsapp my-float" />
      </Link>
    </>
  );
};

export default WhatsappBtn;
