export const services = [
  {
    title: "Digital marketing",
    description:
      "Digital marketing is the key to making your brand stand out in the digital age. As customers increasingly rely on online searches for their needs, your business faces intense competition. A leading digital marketing company in Indore, we specialize in boosting your website’s ranking, driving targeted traffic, and converting visitors into loyal customers. By leveraging these strategies, we ensure your brand thrives in today’s fast-paced digital world.",
    ourServices: [
      {
        name: "SEO",
        content:
          "Enhance your website's visibility with our expert SEO services in Indore, ensuring you rank higher on search engines and attract more organic traffic.",
      },
      {
        name: "SMM (Social Media Marketing)",
        content:
          "Boost your brand's presence on social platforms with our social media marketing (SMM) services in Indore, engaging your audience and driving meaningful interactions through targeted social media strategies.",
      },
      {
        name: "SEM (Search Engine Marketing)",
        content:
          "Maximize your online reach with our SEM services in Indore, combining paid search ads and optimized campaigns to drive instant visibility and conversions.",
      },
      {
        name: "Content Development Services",
        content:
          "Craft compelling and relevant content with our content development services in Indore that resonates with your audience, building trust and authority for your brand across digital channels.",
      },
      {
        name: "Local SEO",
        content:
          "Dominate local search results by optimizing your online presence with our local SEO services in Indore, ensuring your business is easily found by customers in your area.",
      },
      {
        name: "PPC Management",
        content:
          "Achieve cost-effective results with our PPC management services in Indore, driving targeted traffic to your site through carefully managed paid ad campaigns.",
      },
      {
        name: "Video Production",
        content:
          "Captivate your audience with high-quality video content produced by our video production services in Indore that tells your brand's story and enhances your digital marketing efforts.",
      },
      {
        name: "Conversion Rate Optimization (CRO)",
        content:
          "Increase your website's effectiveness by optimizing user experience with our conversion rate optimization (CRO) services in Indore, turning more visitors into customers.",
      },
      {
        name: "Email Marketing",
        content:
          "Connect with your audience through personalized email campaigns by our email marketing services in Indore that drive engagement and foster long-term customer relationships.",
      },
      {
        name: "Online Reputation Management",
        content:
          "Protect and enhance your brand's online reputation with our online reputation management services in Indore, ensuring positive reviews and managing public perception across digital platforms.",
      },
      {
        name: "Influencer Marketing",
        content:
          "Leverage the power of influencer with our influencer marketing services in Indore to reach a broader audience, building credibility and trust through authentic endorsements.",
      },
    ],
    whyUs: [
      {
        name: "Expertise & Experience",
        content:
          "Benefit from our team's deep knowledge and years of experience in digital marketing. As a leading digital marketing company in Indore, we ensure your business receives the best strategies and execution.",
      },
      {
        name: "Tailored Strategies",
        content:
          "We create customized marketing plans that align with your specific business goals and target audience. Our digital marketing services in Indore ensure maximum impact for your brand.",
      },
      {
        name: "Comprehensive Services",
        content:
          "From SEO and SMM to PPC management and content development, our digital marketing agency in Indore offers a full range of services to cover all your needs.",
      },
      {
        name: "Measurable Results",
        content:
          "Our focus is on delivering tangible outcomes through our digital marketing services in Indore, such as improved online visibility, increased traffic, and higher conversion rates.",
      },
      {
        name: "Latest Tools & Trends",
        content:
          "Stay ahead of the competition with our up-to-date approach. Our digital marketing company in Indore utilizes the latest tools and trends in digital marketing to give your business an edge.",
      },
      {
        name: "Client-Centric Approach",
        content:
          "Your success is our priority. As a dedicated digital marketing agency in Indore, we work closely with you to understand your business and deliver results that exceed your expectations.",
      },
      {
        name: "Proven Track Record",
        content:
          "Trust in our proven ability to elevate brands with our digital marketing services in Indore, driving sustainable growth in the digital marketplace.",
      },
    ],
    benefits: [
      {
        name: "Cost-Effective",
        content:
          "Digital marketing services are often more affordable than traditional marketing methods, allowing businesses of all sizes to reach a large audience without breaking the bank.",
      },
      {
        name: "Targeted Audience Reach",
        content:
          "Digital marketing companies enable precise targeting based on demographics, interests, and behaviors, ensuring your message reaches the right audience.",
      },
      {
        name: "Measurable Results",
        content:
          "With digital marketing, you can track and measure the performance of your campaigns in real-time, allowing for data-driven decisions and optimization.",
      },
      {
        name: "Global Reach",
        content:
          "Digital marketing breaks geographical barriers, giving you the ability to reach a global audience and expand your market beyond local boundaries.",
      },
      {
        name: "Enhanced Engagement",
        content:
          "Digital marketing fosters two-way communication with your audience through social media, email, and other channels, leading to higher engagement and customer loyalty.",
      },
      {
        name: "Improved Conversion Rates",
        content:
          "By targeting the right audience with tailored content, digital marketing increases the likelihood of converting visitors into customers.",
      },
      {
        name: "Flexible and Scalable",
        content:
          "Digital marketing strategies can be easily adjusted and scaled to meet changing business needs or market conditions.",
      },
      {
        name: "Brand Building",
        content:
          "Consistent digital presence and content from digital marketing services help build brand awareness, credibility, and trust among your target audience.",
      },
      {
        name: "Competitive Advantage",
        content:
          "Digital marketing allows you to stay competitive in a rapidly evolving market, ensuring your brand remains relevant and top-of-mind.",
      },
      {
        name: "Real-Time Customer Interaction",
        content:
          "Engage with your customers in real-time through various digital channels with digital marketing services, providing immediate responses and enhancing customer satisfaction.",
      },
    ],
  },
  {
    title: "App development ",
    description:
      "App development services in Indore offer a cost-effective way to create tailored, high-functioning applications that captivate users and expand your reach. Partnering with a top app development company in Indore ensures innovative features and seamless user experiences, making your app stand out in a competitive market. From global accessibility to real-time updates, an app development agency in Indore helps turn your vision into a dynamic tool that drives engagement and growth.",
    ourServices: [
      {
        name: "Custom App Development",
        content:
          "Our app development company in Indore specializes in creating bespoke applications tailored to your business needs and goals, ensuring a perfect fit for your requirements.",
      },
      {
        name: "UI/UX Design",
        content:
          "With our app development agency in Indore, enjoy innovative and user-friendly design solutions that enhance the overall user experience and engagement with your app.",
      },
      {
        name: "iOS and Android Development",
        content:
          "We provide expert app development services in Indore for both iOS and Android platforms, ensuring your app reaches users across all major mobile operating systems.",
      },
      {
        name: "App Maintenance and Support",
        content:
          "Our app development company in Indore offers ongoing maintenance and support to keep your app running smoothly and up-to-date with the latest technologies.",
      },
      {
        name: "App Integration",
        content:
          "Seamlessly integrate your app with existing systems and APIs with the help of our app development agency in Indore, enhancing its functionality and performance.",
      },
      {
        name: "Cross-Platform Development",
        content:
          "Benefit from our expertise in app development services in Indore for cross-platform solutions, ensuring a consistent experience across multiple devices and operating systems.",
      },
      {
        name: "App Testing and Quality Assurance",
        content:
          "Ensure your app performs flawlessly with comprehensive testing and quality assurance provided by our app development company in Indore.",
      },
      {
        name: "App Marketing and Launch",
        content:
          "Our app development agency in Indore supports you with marketing strategies and a successful launch plan to maximize your app’s visibility and impact in the market.",
      },
    ],
    whyUs: [
      {
        name: "Expertise & Experience",
        content:
          "At MindCoders, our app development company in Indore brings extensive expertise and experience to every project, ensuring high-quality results that meet your business needs.",
      },
      {
        name: "Tailored Solutions",
        content:
          "We offer customized app development services in Indore designed to align perfectly with your unique requirements and goals, delivering solutions that truly fit your business.",
      },
      {
        name: "Comprehensive Services",
        content:
          "From initial design to final deployment, our app development agency in Indore provides a full range of services, ensuring a seamless development process and exceptional outcomes.",
      },
      {
        name: "Cutting-Edge Technology",
        content:
          "Stay ahead of the curve with our use of the latest tools and technologies in app development services in Indore, ensuring your app is innovative and up-to-date.",
      },
      {
        name: "Client-Centric Approach",
        content:
          "Your success is our priority. Our app development company in Indore works closely with you to understand your vision and deliver results that exceed your expectations.",
      },
      {
        name: "Proven Track Record",
        content:
          "Trust in our app development agency in Indore with a proven track record of successful projects, demonstrating our ability to deliver impactful and effective app solutions.",
      },
      {
        name: "Competitive Edge",
        content:
          "By choosing MindCoders, you gain a competitive advantage in the market, leveraging our app development services in Indore to create standout applications that drive engagement and growth.",
      },
    ],
    benefits: [
      {
        name: "Cost-Effective Solutions",
        content:
          "App development services offer a cost-efficient way to build custom applications, allowing businesses to achieve their goals without overspending.",
      },
      {
        name: "Targeted Functionality",
        content:
          "App development companies provide tailored solutions that align with your specific business needs, ensuring your app delivers maximum value and functionality.",
      },
      {
        name: "Measurable Impact",
        content:
          "With app development services, you can track app performance and user engagement metrics, enabling data-driven decisions and continuous improvement.",
      },
      {
        name: "Global Reach",
        content:
          "App development breaks geographical barriers, giving you the ability to reach and engage a global audience, expanding your market beyond local constraints.",
      },
      {
        name: "Enhanced User Experience",
        content:
          "App development companies focus on creating intuitive and engaging user interfaces, leading to improved user satisfaction and higher retention rates.",
      },
      {
        name: "Improved Business Efficiency",
        content:
          "By integrating advanced features and technologies, app development services enhance your app’s functionality, boosting overall business efficiency.",
      },
      {
        name: "Flexible and Scalable",
        content:
          "App development agencies offer scalable solutions that can be easily adjusted to accommodate growing user demands or changing business needs.",
      },
      {
        name: "Brand Strengthening",
        content:
          "A well-designed app from app development services helps build and strengthen your brand’s presence, credibility, and customer loyalty.",
      },
      {
        name: "Competitive Advantage",
        content:
          "Leverage innovative solutions from app development companies to stay ahead of competitors and capture market share with cutting-edge applications.",
      },
      {
        name: "Real-Time Updates",
        content:
          "Engage with users effectively through real-time updates and notifications provided by app development agencies, enhancing user engagement and satisfaction.",
      },
    ],
  },
  {
    title: "Shopify ",
    description:
      "Our Shopify services in Indore offer a comprehensive solution for building and managing your online store with ease. From designing a stunning, user-friendly storefront to implementing powerful e-commerce functionalities, we provide everything you need to create a successful online business. Our expertise in Shopify services ensures that your store is optimized for performance, responsive across all devices, and equipped with the latest features to enhance the shopping experience. With our tailored approach, we help you navigate the complexities of e-commerce, drive sales, and grow your brand effectively in the digital marketplace.",
    ourServices: [
      {
        name: "Custom Store Design",
        content:
          "MindCoders' Shopify services in Indore include creating bespoke, visually appealing store designs that reflect your brand’s identity and enhance user experience.",
      },
      {
        name: "Theme Development",
        content:
          "We offer customized Shopify services to develop unique themes tailored to your business needs, ensuring a distinctive and engaging online presence.",
      },
      {
        name: "App Integration",
        content:
          "With MindCoders' Shopify services in Indore, integrate essential apps and tools to extend the functionality of your store and streamline operations.",
      },
      {
        name: "Store Setup and Migration",
        content:
          "MindCoders provides complete store setup and seamless migration from other platforms with our Shopify services, making it easy to transition to Shopify without losing data.",
      },
      {
        name: "SEO Optimization",
        content:
          "Improve your store’s visibility with MindCoders' Shopify services in Indore, including SEO strategies that enhance search engine rankings and drive organic traffic.",
      },
      {
        name: "Ongoing Support and Maintenance",
        content:
          "Benefit from continuous support and maintenance with MindCoders' Shopify services, ensuring your store runs smoothly and remains up-to-date with the latest features and security.",
      },
      {
        name: "E-commerce Strategy",
        content:
          "Develop effective e-commerce strategies with MindCoders' Shopify services in Indore to boost sales, optimize customer engagement, and grow your online business.",
      },
    ],
    whyUs: [
      {
        name: "Expertise & Experience",
        content:
          "With MindCoders, you benefit from our extensive expertise and years of experience in delivering top-notch Shopify services in Indore, ensuring your online store is built to the highest standards.",
      },
      {
        name: "Tailored Solutions",
        content:
          "We provide customized Shopify services that align perfectly with your business needs and goals, ensuring a unique and effective e-commerce solution.",
      },
      {
        name: "Comprehensive Support",
        content:
          "Our Shopify services in Indore include complete support from store design to ongoing maintenance, offering a seamless experience and peace of mind.",
      },
      {
        name: "Cutting-Edge Technology",
        content:
          "MindCoders uses the latest tools and technologies in Shopify services, ensuring your store is equipped with advanced features and stays ahead in the competitive market.",
      },
      {
        name: "Client-Centric Approach",
        content:
          "Your success is our priority. MindCoders' Shopify services are designed to address your specific needs and exceed your expectations, ensuring a successful online presence.",
      },
      {
        name: "Proven Track Record",
        content:
          "Trust in MindCoders for Shopify services in Indore with a proven track record of successful projects and satisfied clients, showcasing our ability to deliver impactful results.",
      },
      {
        name: "Competitive Edge",
        content:
          "Choosing MindCoders gives you a competitive advantage with innovative Shopify services that enhance your online store’s performance and visibility.",
      },
    ],
    benefits: [
      {
        name: "Cost-Effective Solutions",
        content:
          "Shopify services offer a cost-efficient way to build and manage your online store, helping you achieve your e-commerce goals without overspending.",
      },
      {
        name: "Tailored Functionality",
        content:
          "Shopify services provide customized solutions that align with your specific business needs, ensuring your store is designed to deliver maximum value and performance.",
      },
      {
        name: "Enhanced User Experience",
        content:
          "With expert Shopify services, you benefit from intuitive and engaging store designs that improve user satisfaction and drive higher retention rates.",
      },
      {
        name: "Comprehensive Support",
        content:
          "Shopify services include end-to-end support, from initial setup and theme development to ongoing maintenance, ensuring your store operates smoothly at all times.",
      },
      {
        name: "SEO Optimization",
        content:
          "Improve your store’s search engine rankings and drive organic traffic with Shopify services that incorporate effective SEO strategies.",
      },
      {
        name: "Global Reach",
        content:
          "Shopify services enable you to reach a global audience, breaking geographical barriers and expanding your market beyond local constraints.",
      },
      {
        name: "Flexible and Scalable",
        content:
          "Shopify services offer scalable solutions that can be adjusted to accommodate growing user demands or changing business needs.",
      },
      {
        name: "Brand Building",
        content:
          "A well-designed store with Shopify services helps build and strengthen your brand’s presence, credibility, and customer loyalty.",
      },
      {
        name: "Competitive Advantage",
        content:
          "Leverage innovative features and technologies from Shopify services to stay ahead of competitors and capture market share with a standout online store.",
      },
      {
        name: "Real-Time Updates",
        content:
          "Engage with your customers effectively through real-time updates and notifications provided by Shopify services, enhancing user engagement and satisfaction.",
      },
    ],
  },
  {
    title: "Web development",
    description:
      "At our web development company in Indore, we specialize in creating high-quality, customized websites that cater to your unique business needs. Our comprehensive website development services in Indore ensure that your online presence is both engaging and effective. From intuitive design and robust functionality to seamless user experiences, our team excels in delivering exceptional web development in Indore. Whether you need a new site or want to enhance your existing one, our expertise in website development in Indore provides the perfect solution to drive your business forward in the digital landscape.",
    ourServices: [
      {
        name: "Custom Website Design",
        content:
          "As a leading web development company in Indore, we create tailor-made website designs that capture your brand’s essence and engage your audience effectively.",
      },
      {
        name: "Responsive Web Development",
        content:
          "Our website development services in Indore ensure your site is fully responsive, providing a seamless experience across all devices and screen sizes.",
      },
      {
        name: "E-commerce Solutions",
        content:
          "Benefit from our expertise in web development in Indore with robust e-commerce solutions that drive sales and streamline online transactions.",
      },
      {
        name: "Content Management Systems (CMS)",
        content:
          "Our website development company in Indore offers CMS integration, allowing you to easily manage and update your website content.",
      },
      {
        name: "SEO Optimization",
        content:
          "Enhance your site’s visibility with our best website development in Indore, which includes SEO-friendly practices to boost your search engine rankings.",
      },
      {
        name: "Performance Optimization",
        content:
          "We ensure your website performs at its best with our web development services, focusing on speed, security, and overall functionality.",
      },
      {
        name: "Ongoing Maintenance and Support",
        content:
          "Our website development in Indore includes continuous support and maintenance to keep your site updated and running smoothly.",
      },
      {
        name: "Custom Web Applications",
        content:
          "As a top web development company in Indore, we develop custom web applications tailored to your specific business requirements, enhancing functionality and user experience.",
      },
    ],
    whyUs: [
      {
        name: "Expertise & Experience",
        content:
          "MindCoders stands out as a top web development company in Indore, offering years of experience and deep expertise to deliver exceptional website solutions.",
      },
      {
        name: "Tailored Solutions",
        content:
          "We provide customized website development services in Indore that are perfectly aligned with your business goals and target audience, ensuring a unique and effective online presence.",
      },
      {
        name: "Comprehensive Offerings",
        content:
          "Our web development in Indore covers a full range of services, from responsive design and CMS integration to e-commerce solutions and SEO optimization.",
      },
      {
        name: "Cutting-Edge Technology",
        content:
          "MindCoders employs the latest technologies and best practices in website development in Indore, ensuring your site is innovative, secure, and performs at its best.",
      },
      {
        name: "Client-Centric Approach",
        content:
          "Your satisfaction is our priority. We work closely with you to understand your needs and deliver results that exceed your expectations, making us the best website development in Indore.",
      },
      {
        name: "Proven Track Record",
        content:
          "Trust MindCoders' web development company in Indore with a proven history of successful projects and happy clients, demonstrating our capability to deliver impactful results.",
      },
      {
        name: "Ongoing Support",
        content:
          "Benefit from continuous support and maintenance with our website development services in Indore, keeping your site up-to-date and running smoothly long after launch.",
      },
    ],
    benefits: [
      {
        name: "Cost-Effective Solutions",
        content:
          "Web development services provide an affordable way to build and maintain a high-quality website, helping businesses achieve their online goals without significant expense.",
      },
      {
        name: "Tailored Functionality",
        content:
          "Web development in Indore offers customized solutions that align with specific business needs, ensuring the website delivers maximum value and performance.",
      },
      {
        name: "Enhanced User Experience",
        content:
          "With expert website development services in Indore, users benefit from intuitive, engaging, and responsive designs that improve overall satisfaction and retention.",
      },
      {
        name: "Comprehensive Features",
        content:
          "Website development in Indore includes a wide range of features such as e-commerce functionality, content management systems, and SEO optimization to support diverse business needs.",
      },
      {
        name: "Improved Visibility",
        content:
          "SEO-friendly practices included in web development services enhance search engine rankings, driving more organic traffic to the website.",
      },
      {
        name: "Global Reach",
        content:
          "Web development allows businesses to expand their reach beyond local boundaries, connecting with a global audience and increasing market opportunities.",
      },
      {
        name: "Flexible and Scalable",
        content:
          "Web development in Indore provides scalable solutions that can easily adapt to changing business needs and growing user demands.",
      },
      {
        name: "Brand Building",
        content:
          "A well-designed website through website development services helps establish and strengthen brand presence, credibility, and customer trust.",
      },
      {
        name: "Competitive Advantage",
        content:
          "Innovative features and up-to-date technologies in web development give businesses a competitive edge, ensuring their website stands out in the market.",
      },
      {
        name: "Real-Time Updates",
        content:
          "Engage users effectively with real-time updates and notifications from website development services, enhancing engagement and customer satisfaction.",
      },
    ],
  },

  {
    title: "Enterprise resource planning ",
    description:
      "Unlock the full potential of your business with our ERP services, tailored to meet your unique needs. As leading ERP software companies in Indore, we provide comprehensive solutions that streamline your operations, enhance efficiency, and improve decision-making. Our ERP software in Indore integrates various business processes into a cohesive system, offering real-time insights and facilitating smoother management. With our expertise in ERP software Indore, you can expect robust, scalable solutions that drive growth and optimize performance across your enterprise.",
    ourServices: [
      {
        name: "Expertise & Experience",
        content:
          "As top ERP software companies in Indore, we bring extensive knowledge and experience to deliver effective ERP solutions tailored to your business needs.",
      },
      {
        name: "Customized Solutions",
        content:
          "Our ERP software in Indore is designed to align perfectly with your specific business processes, ensuring seamless integration and optimized performance.",
      },
      {
        name: "Comprehensive Integration",
        content:
          "Benefit from ERP Indore services that integrate various functions of your business into a unified system, streamlining operations and enhancing efficiency.",
      },
      {
        name: "Real-Time Insights",
        content:
          "Our ERP software Indore provides real-time data and analytics, enabling informed decision-making and improved business management.",
      },
      {
        name: "Scalable and Flexible",
        content:
          "Our ERP solutions are scalable and adaptable, allowing you to easily adjust as your business grows and evolves.",
      },
      {
        name: "Client-Centric Approach",
        content:
          "We prioritize your needs and work closely with you to implement ERP solutions that exceed your expectations and drive success.",
      },
    ],
    whyUs: [
      {
        name: "Industry Expertise",
        content:
          "MindCoders stands out among ERP software companies in Indore with our deep industry knowledge and experience in delivering tailored ERP solutions that meet diverse business needs.",
      },
      {
        name: "Customized ERP Solutions",
        content:
          "We provide bespoke ERP software in Indore designed to seamlessly integrate with your existing processes, ensuring a smooth and efficient implementation.",
      },
      {
        name: "Comprehensive Integration",
        content:
          "As a leading ERP Indore provider, MindCoders offers solutions that unify various business functions into a single, cohesive system, enhancing operational efficiency.",
      },
      {
        name: "Real-Time Data and Analytics",
        content:
          "Our ERP software Indore delivers real-time insights and analytics, empowering you with the information needed for strategic decision-making and effective management.",
      },
      {
        name: "Scalable and Adaptable",
        content:
          "MindCoders' ERP solutions are designed to grow with your business, offering scalability and flexibility to adapt to evolving needs and market conditions.",
      },
      {
        name: "Client-Focused Approach",
        content:
          "We work closely with you to ensure that our ERP services align with your goals, delivering results that exceed expectations and drive business success.",
      },
    ],
    benefits: [
      {
        name: "Streamlined Operations",
        content:
          "ERP services integrate various business processes into a unified system, enhancing efficiency and reducing operational complexities.",
      },
      {
        name: "Improved Data Accuracy",
        content:
          "By centralizing data, ERP systems reduce errors and ensure that information across the organization is accurate and consistent.",
      },
      {
        name: "Real-Time Insights",
        content:
          "ERP solutions provide real-time data and analytics, enabling timely and informed decision-making for better management.",
      },
      {
        name: "Enhanced Productivity",
        content:
          "Automated processes and streamlined workflows from ERP software increase productivity and reduce manual tasks and redundancies.",
      },
      {
        name: "Scalability and Flexibility",
        content:
          "ERP systems are designed to scale with business growth and adapt to changing needs, ensuring long-term relevance and support.",
      },
      {
        name: "Cost Savings",
        content:
          "By improving efficiency and reducing errors, ERP services help lower operational costs and enhance overall financial management.",
      },
      {
        name: "Improved Customer Service",
        content:
          "With integrated customer data and streamlined processes, ERP solutions enhance customer service and satisfaction by providing timely and accurate information.",
      },
      {
        name: "Better Compliance",
        content:
          "ERP systems help ensure regulatory compliance by providing tools for accurate reporting and data management.",
      },
      {
        name: "Increased Collaboration",
        content:
          "Centralized data and integrated processes foster better communication and collaboration across departments, enhancing overall organizational cohesion.",
      },
    ],
  },
];
