import React from "react";
import { jobData } from "../data/jobData";
import JobCard from "../components/JobCard";

const CareerPage = () => {
  return (
    <>
      {/* Hero section Begin */}
      <section className="container pt-5">
        <div className="row overflow-x-hidden flex-wrap justify-center lg:justify-between">
          <div className="col-xl-8 col-lg-7 col-md-8 col-sm-12 mb-6 lg:mb-0 left-section">
            <h2 className="text-3xl sm:text-4xl font-semibold career-heading mb-6">
              Join the <span className="text-info">Mind Coders Team</span>
            </h2>
            <div>
              <div data-aos="fade-right" data-aos-duration="1000" className="mb-4">
                <h4 className="font-bold text-xl">a. Career Opportunities</h4>
                <p>
                  Explore exciting career opportunities at Mind Coders. We are
                  always on the lookout for passionate and talented individuals
                  who share our commitment to excellence and client success.
                </p>
              </div>
              <div data-aos="fade-right" data-aos-duration="1200" className="mb-4">
                <h4 className="font-bold text-xl">b. Culture and Values</h4>
                <p>
                  Learn about our values, culture, and the unique qualities that
                  make Mind Coders a dynamic and rewarding place to work.
                </p>
              </div>
              <div data-aos="fade-right" data-aos-duration="1400" className="mb-4">
                <h4 className="font-bold text-xl">c. How to Apply</h4>
                <p>
                  Ready to take your career to the next level? Discover the
                  application process and explore how you can become a valuable
                  part of the Mind Coders team. <br />
                  <span>
                    Send your resume to <b>hr@mindcoders.in</b>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div data-aos="fade-left" data-aos-duration="1300" className="col-xl-4 col-lg-5 col-md-8 col-sm-10 pt-5 right-section">
            <img
              src={`${window.location.origin}/assets/img/GalleryImg/G2.webp`}
              alt="Mind Coders Team"
              loading="lazy"
              className="w-full rounded-lg object-cover career-img"
            />
          </div>
        </div>
      </section>

      {/* job of mind coders  start*/}
      <section data-aos="fade-up" data-aos-duration="1000" className="p-3 md:p-5 overflow-hidden">
        <h2 className="h2 text-center">
          Current <span className="text-info">Openings</span>{" "}
        </h2>
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {jobData.map((job, index) => (
            <JobCard
              key={index}
              title={job.title}
              experience={job.experience}
              skills={job.skills}
            />
          ))}
        </div>
      </section>
      {/* job of mind coders  end*/}
      {/* job form start */}
      <section data-aos="fade-up" data-aos-duration="1000" className="input-section overflow-x-hidden py-8" id="job-form">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white shadow-lg p-6 w-full rounded-lg  mx-auto">
            <h2 className="text-center text-2xl md:text-3xl font-semibold text-gray-900 mb-6">
              Job Application <span className="text-info">Form</span>
            </h2>
            <form
              className="flex flex-col"
              onSubmit="Mail2(); reset(); return false;"
              data-gtm-form-interact-id={0}
            >
              <div className="mb-4">
                <input
                  type="text"
                  className="form-input w-full p-3 border border-gray-300 rounded-md"
                  placeholder="Full Name"
                  required
                  id="fullname"
                  aria-label="name"
                />
              </div>
              <div className="mb-4">
                <input
                  type="email"
                  className="form-input w-full p-3 border border-gray-300 rounded-md"
                  placeholder="Email"
                  required
                  id="emailId"
                  aria-label="email"
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  className="form-input w-full p-3 border border-gray-300 rounded-md"
                  placeholder="Phone Number"
                  required
                  id="phonenumber"
                />
              </div>
              <div className="mb-4">
                <select
                  className="form-select w-full p-3 border border-gray-300 rounded-md"
                  required
                  id="job_profile"
                  data-gtm-form-interact-field-id={0}
                >
                  <option value="">Job Role</option>
                  <option value="IT Project Bidder">IT Project Bidder</option>
                  <option value="Data analyst Bidder">
                    Data Analyst Bidder
                  </option>
                  <option value="Full Stack MERN Trainer">
                    Full Stack MERN Trainer
                  </option>
                  <option value="Python Trainer">Python Trainer</option>
                  <option value="Data analyst Trainer">
                    Data Analyst Trainer
                  </option>
                  <option value="Digital Marketing Executive">
                    Digital Marketing Executive
                  </option>
                </select>
              </div>
              <div className="mb-4">
                <textarea
                  name="cover_letter"
                  className="form-textarea w-full p-3 border border-gray-300 rounded-md"
                  placeholder="Cover Letter"
                  required
                  id="cover_letter"
                  defaultValue={""}
                />
              </div>
              <div className="mb-4">
                <label htmlFor="myfile" className="block mb-2 text-gray-700">
                  Upload Resume
                </label>
                <input
                  type="file"
                  id="myfile"
                  className="w-full p-3 border border-gray-300 rounded-md"
                  required
                />
              </div>
              <button
                type="submit"
                className="btn w-full bg-blue-500 text-white py-3 rounded-md hover:bg-blue-600 transition duration-300"
              >
                Apply
              </button>
            </form>
          </div>
        </div>
      </section>

      {/* job form end */}
    </>
  );
};

export default CareerPage;
