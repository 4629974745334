import React from "react";
import { dsaInterviewCourse } from "../../data/courseCurriculamData";
import Tabs from "../../components/Tabs";
import { dsaInterviewCourseData } from "../../data/courseTabData";
import { Icons } from "../../data/animatedBeamIconsData";
import { AnimatedBeamComponent } from "../../components/AnimatedBeamComponent.tsx";
import { Helmet } from "react-helmet";
import { metaData } from '../../data/metaData.js';

const MasterDSA = () => {
  const currentMetaData = metaData.find((meta) =>
    meta.title.includes("Data Structure And Algorithm with C and C++ Course in Indore")
  );
  return (
    <>
     <Helmet>
        <title>{currentMetaData?.title}</title>
        <meta name="title" content={currentMetaData?.metaTitle} />
        <meta name="description" content={currentMetaData?.metaDescription} />
        <meta name="keywords" content={currentMetaData?.metaKeywords} />
      </Helmet>
      {/*hero section start*/}
      <section className="d-flex align-items-center position-relative">
        <div className="container ">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6">
              <div className="hero-content-wrap d-flex gap-2">
                <div className="divider">
                  <span className="saperater" />
                </div>
                <div>
                  <h1 className="course-heading h1">
                    Master DSA with C and C++ Course in Indore
                  </h1>
                  <p className="course-overview">
                    The Data Structures and Algorithms (DSA) course with C/C++
                    in Indore focuses on mastering core concepts to solve
                    complex computational problems. You’ll learn key data
                    structures like arrays, linked lists, stacks, and trees, and
                    algorithms for sorting, searching, and dynamic programming.
                    The course includes interview preparation to help you
                    succeed in coding interviews, along with assured internships
                    and placement assistance. Through hands-on projects and
                    coding challenges, you'll develop the skills needed for
                    real-world problem-solving and to excel in technical
                    interviews.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hero-content-wrap">
                <AnimatedBeamComponent icons={Icons} />
              </div>
            </div>
          </div>
          <div className="flex flex-col ">
            <main className="flex-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4 sm:p-6">
              {dsaInterviewCourse.map((course, index) => (
                <div
                  key={index}
                  className="bg-slate-100 rounded-lg shadow-sm p-4 flex flex-col"
                >
                  <h3 className="text-lg font-semibold mb-2">
                    {course.name || course.heading}
                  </h3>
                  <p className="text-muted-foreground text-sm">
                    <ul>
                      {course.topics.map((topic, i) => (
                        <li className="list-disc" key={i}>
                          {topic}
                        </li>
                      ))}
                    </ul>
                  </p>
                </div>
              ))}
            </main>
          </div>
        </div>
      </section>
      {/*hero section end*/}

      <Tabs content={dsaInterviewCourseData} />
    </>
  );
};

export default MasterDSA;
