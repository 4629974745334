import React from "react";
import { ReactTyped } from "react-typed";

const ReactTypedComp = () => {
  return (
    <div>
      <ReactTyped
        strings={["Authorized by", "Licensed by"]}
        typeSpeed={70}
        backSpeed={70}
        loop
        className="text-[#05314b] text-lg md:text-2xl lg:text-6xl font-extrabold"
      />
    </div>
  );
};

export default ReactTypedComp;
