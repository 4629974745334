import React from "react";
import Tabs from "../../components/Tabs";
import { python } from "../../data/courseCurriculamData";
import { pythonData } from "../../data/courseTabData";
import { Icons } from "../../data/animatedBeamIconsData";
import { AnimatedBeamComponent } from "../../components/AnimatedBeamComponent.tsx";
import { metaData } from "../../data/metaData.js";
import { Helmet } from "react-helmet";

const PythonProgrammingPage = () => {
  const currentMetaData = metaData.find((meta) =>
    meta.title.includes("Python programming")
  );
  return (
    <>
      <Helmet>
        <title>{currentMetaData.title}</title>
        <meta name="title" content={currentMetaData.metaTitle} />
        <meta name="description" content={currentMetaData.metaDescription} />
        <meta name="keywords" content={currentMetaData.metaKeywords} />
      </Helmet>
      {/*hero section start*/}
      <section className="d-flex align-items-center position-relative">
        <div className="container">
          <div className="row mt-5 justify-content-between align-items-center">
            <div className="col-lg-6">
              <div className="hero-content-wrap d-flex gap-2">
                <div className="divider">
                  <span className="saperater" />
                </div>
                <div>
                  <h1 className="course-heading h1">
                    Python programming Course in Indore
                  </h1>
                  <p className="course-overview">
                    The Python Programming course in indore is designed to
                    introduce you to one of the most versatile and
                    beginner-friendly programming languages. You will start with
                    the basics of Python, including syntax, data types, and
                    control structures, and progress to advanced topics like
                    object-oriented programming (OOP), file handling, and
                    libraries like NumPy and Pandas. With hands-on projects and
                    real-world applications, this course is perfect for
                    beginners, data enthusiasts, and developers looking to
                    expand their skill set in Python for web development, data
                    science, or automation.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="hero-content-wrap">
                <AnimatedBeamComponent icons={Icons} />
              </div>
            </div>
          </div>
          <div className="flex flex-col ">
            <main className="flex-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4 sm:p-6">
              {python.map((course, index) => (
                <div
                  key={index}
                  className="bg-slate-100 rounded-lg shadow-sm p-4 flex flex-col"
                >
                  <h3 className="text-lg font-semibold mb-2">
                    {course.name || course.heading}
                  </h3>
                  <p className="text-muted-foreground text-sm">
                    <ul>
                      {course.topics.map((topic, i) => (
                        <li className="list-disc" key={i}>
                          {topic}
                        </li>
                      ))}
                    </ul>
                  </p>
                </div>
              ))}
            </main>
          </div>
        </div>
      </section>
      {/*hero section end*/}

      <Tabs content={pythonData} />
    </>
  );
};

export default PythonProgrammingPage;
