export const testimonialData = [
  
  {
    id: 1,
    body:"I had an amazing experience with the ReactJS course at MindCoders, the top web development institute in Indore. The course offered a perfect blend of front-end and back-end development, providing me with a strong foundation in both. The hands-on projects and expert guidance really set this institute apart, making it the best choice for anyone looking to master ReactJS course in Indore.",
    name:"Raja Sharma",
    img: "assets/img/users.webp",
    rating: 5,
    },
    {
        id: 2,
        body:"My experience with the Digital Marketing course at MindCoders in Indore was truly transformative. As one of the top training institutes in Digital Marketing in indore, they provided in-depth knowledge of SEO, social media,Email Marketing and more, all with hands-on practice. The unique blend of creativity and strategy taught here is unmatched, making it the ideal place to launch your digital marketing career in Indore.",
        name:"Shweta Bhatia",
        img: "assets/img/users.webp",
        rating: 4,
        
    },
    {
        id: 3,
        body:"I made the best decision of my life by joining Mindcoders, the faculty is super awesome, especially Himanshu sir and Bhimesh sir. i gained the ability to be an industry professional now and am looking to grow more in the future.",
        name:"Palak Tiwari",
        img: "assets/img/users.webp",
        rating: 3,
        
    },
    {
        id: 4,
        body:"Mindcoders offered me Corporate Environment which is strictly needed for future endeavors. I am thankful to the team and can say this is the best IT and Non IT Training Institute in Indore",
        name:"Anjali Patel",
        img: "assets/img/users.webp",
        rating: 4,
        
    },
    {
        id: 5,
        body:"I don't have words to describe the faculty of Mindcoders and can say this is truly helpful if you are searching Web Development Course in Indore. Don't look any further and Join the best institute in Indore for your flying growth.",
        name:"Sajmiya Singh",
        img: "assets/img/users.webp",
        rating: 5,
        
    },
    {
        id: 6,
        body:"Classes are superb for mern stack in indore specially shiv sir and nameet sir. They helped me throughout my course and practicals, undoubtedly best mernstack classes in indore.",
        name:"Tanya Sharma",
        img: "assets/img/users.webp",
        rating: 4,
        
    },
    {
        id: 7,
        body:"Mindcoders helped me in getting placements the guidelines provided by bhimesh sir had been significant. This is the best digital marketing course in Indore ",
        name:"Sahil Purohit",
        img: "assets/img/users.webp",
        rating: 5,
        
    },
    {
    id: 8,
    body:"Curriculum is top-notch and updated and specilly the extra efforts which they put is magnificient. Vinay sir made these claases best data analytics course in indore",
    name:"Himanshu Bhojak",
    img:"assets/img/users.webp",
    rating: 4,
    },

    {
        id:9,
        body:"Bhawarkua location make this best digital marketing course in indore for its vibrant business hub ",
        name:"Deepesh Singh",
        img:"assets/img/users.webp",
        rating: 5,
    },
    {
        id:10,
        body:"I was an frontend developer intern here and krishnkant sir helped me to grasp each and every practicals of it. This made them best mernstack training course in indore.",
        name:"Ansh Itouria.",
        img:"assets/img/users.webp",
        rating: 4,
    },
];
