import React from "react";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const SwiperSection = ({data}) => {
  
    return (
      <section>
      <Swiper
        className="mySwiper h-60 overflow-hidden rounded-2xl"
        slidesPerView={1}
        spaceBetween={32}
        modules={[Autoplay, Pagination]}
        loop={true}
        centeredSlides={true}
        // pagination={{ clickable: true }}
        autoplay={{ delay: 4000, disableOnInteraction: false }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 16,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 24,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 32,
          },
          1280: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
        }}
      >
        {data?.map((item) => (
          <SwiperSlide key={item.id}>
            <img src={item.image} loading="lazy" className="w-full h-full object-cover aspect-[2/1]" alt="Life at Mindcoders" />
          </SwiperSlide>
        ))}
      </Swiper>
      {/* <div className="swiper-pagination"></div> */}
    </section>
    
      );
}

export default SwiperSection;