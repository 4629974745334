import React from "react";
import InternCard from "../components/cards/InternCard";
// import Breadcrumb from "../components/Breadcrumb";

const Internship = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 mt-3">
            <h2 className="h2 text-center">
              Happy Learners Of <span className="text-info">Mindcoders</span>{" "}
            </h2>
          </div>
        </div>
        <div data-aos="fade-up" data-aos-duration="1000" className="row mt-5 justify-content-center">
          <InternCard />
        </div>
      </div>
    </>
  );
};

export default Internship;
