export const backend = [
  {
    name: "Node JS",
    topics: [
      "Introduction to Node JS",
      "Node JS Basics for Beginners",
      "Core Concepts of Node JS",
    ],
  },
  {
    name: "Python",
    topics: [
      "An Introduction to Python",
      "Exploring the Fundamentals of Python",
      "Core Concepts of Python",
    ],
  },
  {
    name: "PHP",
    topics: [
      "Introduction to PHP",
      "Building the Foundation Fundamentals of PHP",
      "Core Concepts of PHP",
    ],
  },
  {
    name: "Node JS Frameworks",
    topics: ["Express JS", "Connection with Database", "Mongoose and MongoDB"],
  },
  {
    name: "Python Frameworks",
    topics: ["Django", "Fast API", "Flux"],
  },
  {
    name: "PHP Frameworks",
    topics: ["Laravel", "PHP myAdmin Database", "Working flow optimization"],
  },
];

export const dataAnalitics = [
  {
    heading: "Statistics",
    topics: [
      "Descriptive Statistics",
      "Probability and Probability Distributions",
      "Statistics Tests and Data Analysis",
    ],
  },
  {
    heading: "Python",
    topics: [
      "An Introduction to Python for Data Science",
      "Data Manipulation and File Handling",
      "Data Visualization with Python",
    ],
  },
  {
    heading: "Data Science",
    topics: [
      "Introduction to R and RStudio",
      "Data Manipulation and Data Handling in R",
      "Data Visualization with R",
    ],
  },
  {
    heading: "SQL",
    topics: [
      "Introduction to SQL and Advance SQL Queries",
      "Data Manipulation with SQL",
      "SQL for Data Analytics",
    ],
  },
  {
    heading: "Power BI",
    topics: [
      "Data transformation and cleaning",
      "Creating interactive reports",
      "Advance Power BI Features",
    ],
  },
  {
    heading: "Tableau",
    topics: [
      "Data Exploration and Preparation",
      "Building interactive dashboards",
      "Advanced Tableau features",
    ],
  },
];

export const digitalMarketing = [
  {
    heading: "SEO",
    topics: ["ON Page SEO", "Technical SEO", "OFF Page SEO"],
  },
  {
    heading: "SEM",
    topics: ["Google Ads", "Video Ads", "YouTube Ads"],
  },
  {
    heading: "SMO",
    topics: [
      "Brands Analysis, Market Analysis",
      "Content Writing",
      "Poster Designs",
    ],
  },
  {
    heading: "SMM",
    topics: [
      "Facebook Advertising",
      "Instagram Advertising",
      "LinkedIn Advertising",
    ],
  },
  {
    heading: "Email Marketing",
    topics: [
      "Introduction of Email Marketing",
      "Template Design",
      "Bulk Email Sending",
    ],
  },
  {
    heading: "Affiliate Marketing",
    topics: [
      "Introduction to Affiliate Marketing",
      "Affiliate Marketing Programs",
      "Affiliate Marketing Platforms",
    ],
  },
];

export const frontend = [
  {
    heading: "HTML",
    topics: [
      "Introduction to HTML",
      "HTML Basics for Beginners",
      "Core Concepts of HTML",
    ],
  },
  {
    heading: "CSS",
    topics: [
      "An Introduction to CSS3",
      "Exploring the Fundamentals of CSS3",
      "Learn Advanced CSS3",
    ],
  },
  {
    heading: "Bootstrap",
    topics: [
      "Introduction to Bootstrap",
      "Building the Foundation Fundamentals of Bootstrap",
      "Advanced Bootstrap in Nutshell",
    ],
  },
  {
    heading: "JavaScript",
    topics: [
      "Introduction to JavaScript",
      "Complexity of JavaScript",
      "Advance JavaScript",
    ],
  },
  {
    heading: "Tailwind CSS",
    topics: [
      "Introduction to Tailwind",
      "Building the Foundation Fundamentals of Tailwind",
      "Advanced Tailwind",
    ],
  },
];

export const mern = [
  {
    heading: "Front-End",
    topics: [
      "Introduction to HTML, CSS, JavaScript",
      "Advance JavaScript",
      "Core Concepts of Client Side Rendering",
    ],
  },
  {
    heading: "Back-End",
    topics: [
      "An Introduction to Node JS",
      "Exploring the Fundamentals of Express JS",
      "Learn About JWT Tokens",
    ],
  },
  {
    heading: "Frameworks and Libraries",
    topics: ["React for frontend", "Redux for state management", "Express JS"],
  },
  {
    heading: "Database",
    topics: [
      "Introduction to MongoDB",
      "Connection with Mongoose",
      "Define Mongoose Schema",
    ],
  },
  {
    heading: "React JS",
    topics: [
      "Introduction to React",
      "Routing with React Router",
      "Advance React",
    ],
  },
];

export const react = [
  {
    heading: "HTML",
    topics: [
      "Introduction to HTML",
      "HTML Basics for Beginners",
      "Core Concepts of HTML",
    ],
  },
  {
    heading: "CSS",
    topics: [
      "An Introduction to CSS3",
      "Exploring the Fundamentals of CSS3",
      "Learn Advanced CSS3",
    ],
  },
  {
    heading: "Bootstrap",
    topics: [
      "Introduction to Bootstrap",
      "Building the Foundation Fundamentals of Bootstrap",
      "Advanced Bootstrap in a Nutshell",
    ],
  },
  {
    heading: "JavaScript",
    topics: [
      "Introduction to JavaScript",
      "Complexity of JavaScript",
      "Advance JavaScript",
    ],
  },
  {
    heading: "React JS",
    topics: [
      "Introduction to React",
      "Routing with React Router",
      "Advance React",
    ],
  },
  {
    heading: "Redux",
    topics: ["Introduction to Redux", "Redux Toolkit", "Advanced Redux"],
  },
];

export const dsa = [
  {
    heading: "Analysis of Algorithms",
    topics: [
      "Asymptotic Analysis",
      "Big O Notation",
      "Omega Notation",
      "Theta Notation",
      "Analysis of Loops",
      "Analysis of Recursion",
    ],
  },
  {
    heading: "Data Structures",
    topics: [
      "Arrays",
      "Linked Lists",
      "Stacks",
      "Queues",
      "Trees",
      "Hash Tables",
      "And More...",
    ],
  },
  {
    heading: "Algorithm Design Techniques",
    topics: [
      "Divide and Conquer",
      "Dynamic Programming",
      "Greedy Algorithms",
      "Backtracking",
    ],
  },
  {
    heading: "Mathematics for Algorithms",
    topics: [
      "GCD Computations",
      "Prime Checking",
      "Factorials",
      "Power Computations",
    ],
  },
  {
    heading: "Advanced Algorithms",
    topics: [
      "0-1 Knapsack Problem",
      "Matrix Chain Multiplication",
      "Egg Dropping Puzzle",
      "Longest Increasing Subsequence",
    ],
  },
  {
    heading: "String Processing",
    topics: [
      "Naive Pattern Searching",
      "KMP Algorithm",
      "Anagram Searching",
      "Longest Substring with Distinct Characters",
    ],
  },
  {
    heading: "Sorting and Searching",
    topics: [
      "Radix Sort",
      "Counting Sort",
      "Efficient Searching in Rotated Arrays",
    ],
  },
];

export const python = [
  {
    heading: "Introduction to Python",
    topics: [
      "Background Part 1: IO Devices, CPU, and Memory",
      "Background Part 2: Computer Organization",
      "Why Do We Need Programming Languages",
      "Python Introduction and Installation",
    ],
  },
  {
    heading: "Python Basics",
    topics: [
      "Variables and Data Types",
      "Type Conversion in Python",
      "Input and Output in Python",
      "Operators in Python",
    ],
  },
  {
    heading: "Control Flow",
    topics: [
      "if, else, and elif in Python",
      "Loops in Python: While and For Loops",
      "Break and Continue in Python",
      "Nested Loop and Pattern Printing in Python",
    ],
  },
  {
    heading: "Functions in Python",
    topics: [
      "Introduction to Functions in Python",
      "Applications of Functions",
      "Default and Keyword Arguments",
      "Returning Multiple Values",
    ],
  },
  {
    heading: "Object-Oriented Programming (OOP)",
    topics: [
      "Introduction to OOPs: Classes and Objects",
      "Encapsulation and Class Instance Attributes",
      "Inheritance and Types of Inheritance",
      "Polymorphism and Operator Overloading",
    ],
  },
  {
    heading: "Advanced Python Concepts",
    topics: [
      "String Operations and Pattern Searching",
      "Decimal to Binary Conversion",
      "List Comprehensions in Python",
      "Object-Oriented Design Patterns",
    ],
  },
];

export const pythonFullStack = [
  {
    heading: "Introduction to Web Development",
    topics: [
      "Overview of client-server architecture",
      "Web Development Tools",
      "Linux Commands",
      "Overview of Web Development",
    ],
  },
  {
    heading: "Django Basics",
    topics: [
      "Introduction to Django",
      "Installing Django",
      "Creating the First Project",
      "Difference between ASGI and WSGI Servers",
      "Creating a Django App",
      "Understanding Django Files and Modules",
      "Returning HTTP Response and Understanding URLs",
      "Creating Dynamic URLs",
      "Class-based Views vs Function-based Views",
    ],
  },
  {
    heading: "Django Templates",
    topics: [
      "Django Template Language",
      "Template Inheritance",
      "Adding CSS",
      "Mini Project: Guess the Lucky Number",
    ],
  },
  {
    heading: "Django Models",
    topics: [
      "Installing Databases (MySQL, PostgreSQL, SQLite)",
      "Defining Models and Fields",
      "Relationships between Models",
      "Understanding ORM",
      "CRUD Operations using Django Shell",
    ],
  },
  {
    heading: "Building Forms in Django",
    topics: [
      "Django HTML Forms",
      "Django Model Forms",
      "Adding Validation",
      "Null vs Blank",
      "Create vs Save vs get_or_create",
      "Using Faker to Insert Fake Data",
      "Mastering Django Querysets",
    ],
  },
  {
    heading: "Advanced Django Concepts",
    topics: [
      "Django Middleware",
      "Creating Custom Middleware",
      "User Authentication",
      "Django Signals",
      "Aggregations and Annotations",
    ],
  },
];

export const javaProgramming = [
  {
    heading: "Java Basics",
    topics: [
      "Java Introduction (Features, Applications and Working of JVM)",
      "Writing First Program in Java",
      "Variables in Java",
      "Non Primitive Types",
      "Wrapper Classes",
      "Autoboxing and Unboxing",
      "Type Conversion",
      "Operators (Arithmetic, Logical, Bitwise)",
    ],
  },
  {
    heading: "Flow Control",
    topics: [
      "if, else and elif",
      "Switch Statement",
      "Loops (While, For, ForEach)",
      "Break and Continue",
      "Nested Loops",
      "Pattern Printing",
      "Fibonacci Numbers",
      "Prime Checking",
    ],
  },
  {
    heading: "Functions",
    topics: [
      "Introduction to Functions",
      "Applications of Functions",
      "Command Line Arguments",
      "Prime Factorization",
    ],
  },
  {
    heading: "Arrays",
    topics: [
      "Introduction to Arrays",
      "Multidimensional Arrays",
      "Array Sorting",
      "Count Distinct Elements",
      "Array-based Problems",
    ],
  },
  {
    heading: "Strings",
    topics: [
      "Strings in Java",
      "Escape Sequences and Raw Strings",
      "StringBuffer and StringBuilder",
      "Pattern Searching",
      "Palindrome Checking",
      "String Reversal",
    ],
  },
  {
    heading: "Java OOPs",
    topics: [
      "Class and Objects",
      "Encapsulation",
      "Inheritance",
      "Polymorphism",
      "Method Overriding",
      "Abstraction",
      "Abstract Classes",
      "Interfaces",
    ],
  },
  {
    heading: "Advanced Java",
    topics: ["File I/O", "MultiThreading", "Exception Handling", "BigInteger"],
  },
  {
    heading: "Java Collections",
    topics: [
      "Introduction to Java Collections Framework",
      "Generics",
      "Collections Hierarchy",
    ],
  },
  {
    heading: "Java Streams & Lambda Expressions",
    topics: [
      "Introduction to Lambda Expressions",
      "Method References",
      "Introduction to Streams in Java",
      "Stream Hierarchy and Methods",
    ],
  },
];

export const javaFullStack = [
  {
    heading: "Java OOPS Fundamentals",
    topics: [
      "Objects and Classes",
      "Inheritance",
      "Polymorphism",
      "Abstraction",
      "Encapsulation",
      "Singleton Design Pattern",
    ],
  },
  {
    heading: "Advanced Java Features",
    topics: [
      "Java 8 Functional Interfaces & Collections",
      "Lambda Expressions",
      "Generics",
      "Streams",
      "Multithreading",
      "HashMap",
    ],
  },
  {
    heading: "Maven for Project Management",
    topics: [
      "Understanding Maven",
      "Working with POM.xml",
      "Maven Repositories",
      "Maven Lifecycle",
    ],
  },
  {
    heading: "Spring Boot Basics",
    topics: [
      "Introduction to Spring Boot",
      "Embedded Servers",
      "Logging Levels",
      "Spring Profiles",
    ],
  },
  {
    heading: "REST API & Spring MVC",
    topics: [
      "REST API Basics",
      "HTTP Requests and Responses",
      "POSTMAN and CURL for API Testing",
      "Spring MVC Framework",
      "Annotations and Lombok",
    ],
  },
  {
    heading: "Spring IOC & Dependency Injection",
    topics: [
      "Spring IOC Container",
      "Dependency Injection",
      "Annotations and Enums",
      "Beans Configuration",
    ],
  },
  {
    heading: "Java Database Connectivity (JDBC)",
    topics: [
      "JDBC Basics",
      "In-Memory vs. Disk Storage",
      "Spring Boot Database Connection",
      "JPA & Hibernate",
    ],
  },
  {
    heading: "Minor Project: Digital Library",
    topics: [
      "Project Planning",
      "Custom Queries with JPQL",
      "Data Modelling",
      "HLSD",
    ],
  },
  {
    heading: "Unit Testing & Caching",
    topics: [
      "JUnit & Mockito",
      "Redis and Caching Concepts",
      "Server Cache vs. Browser Cache",
    ],
  },
  {
    heading: "Spring Security & Authentication",
    topics: [
      "Basic Authentication",
      "Authorization with In-Memory Database",
      "OAuth2 Integration with Spring Boot",
    ],
  },
  {
    heading: "Microservices",
    topics: [
      "Microservices Architecture",
      "Eureka Server",
      "Intercommunication between Microservices",
      "Kafka Integration",
    ],
  },
];

export const dataScienceCourse = [
  {
    heading: "Excel for Data Analysis",
    topics: [
      "Data Entry in Excel",
      "Data Formatting and Validation",
      "Functions in Excel",
      "Pivot Table & Charts",
      "Visual Basic Analysis",
    ],
  },
  {
    heading: "SQL and Database Management",
    topics: [
      "Introduction to DBMS",
      "Installing MySQL",
      "Creating Tables and Databases",
      "SQL Commands: Joins, Unions, Nested Queries",
      "Normalization in DBMS",
    ],
  },
  {
    heading: "Python for Data Science",
    topics: [
      "Introduction to Python",
      "Data Structures: Lists, Tuples, Dictionaries",
      "NumPy for Numerical Data Manipulation",
      "Pandas for DataFrames",
      "Data Cleaning and Transformation",
    ],
  },
  {
    heading: "Data Visualization",
    topics: [
      "Introduction to Data Visualization",
      "Static Plots using Matplotlib",
      "Advanced Visualization with Seaborn",
      "Interactive Visualizations with Plotly",
      "Exploratory Data Analysis (EDA)",
    ],
  },
  {
    heading: "Power BI",
    topics: [
      "Introduction to Power BI",
      "Connecting to Data Sources",
      "Data Modeling in Power BI",
      "Creating Interactive Dashboards",
      "Advanced Power BI Features: DAX, Drill-through",
    ],
  },
  {
    heading: "Statistics for Data Science",
    topics: [
      "Types of Data: Categorical vs. Numerical",
      "Descriptive Statistics: Mean, Median, Mode",
      "Probability Distributions: Binomial, Poisson",
      "Inferential Statistics: Hypothesis Testing",
      "Regression Analysis: Linear Regression",
    ],
  },
  {
    heading: "Tableau for Data Visualization",
    topics: [
      "Basic Plots: Bar, Line, Scatter",
      "Calculated Fields and Table Calculations",
      "Advanced Plots: Heatmap, Tree Map, Waterfall Chart",
      "Designing Dashboards in Tableau",
      "Clustering and Advanced Analytics in Tableau",
    ],
  },
  {
    heading: "Machine Learning & AI",
    topics: [
      "Introduction to AI and Machine Learning",
      "Linear Regression: Model Training and Prediction",
      "Support Vector Machines",
      "Decision Trees and Random Forests",
      "K-Means Clustering",
    ],
  },
];
export const dsaInterviewCourse = [
  {
    heading: "Introduction of DATA STRUCTURE AND ALGORITHM",
    topics: [
      "Arrays",
      " Linked Lists",
      "  Stacks",
      "Queues",
      "Trees",
      "Hash Tables",
      "And More..",
    ],
  },
  {
    heading: "Intermediate of DATA STRUCTURE AND ALGORITHM",
    topics: [
      "Dynamic Programming (DP) and Memoization",
      "Greedy Algorithms",
      "Problem Solving with Graph Algorithms",
      " Algorithm Optimization Techniques",
      "And more..",
    ],
  },
  {
    heading:"Advanced of DATA STRUCTURE AND ALGORITHM",
    topics:[
      "Advanced Graph Algorithms",
      "Advanced Tree Structures",
      "Amortized Analysis and Probabilistic Data Structures",
      "Approximation and Heuristic Algorithms",
      "Multithreading and Parallel Algorithms",
    ]
  },
  {
    heading:"Introduction of C & C++",
    topics:[
      "Origin and Evolution",
      " Procedural vs. Object-Oriented Paradigm",
      " Performance and Efficiency",
      "Standard Libraries and Templates",
      "Use Cases and Applications",
    ]
  },
  {
    heading:"Intermediate of  C & C++",
    topics:[
      "Memory Management and Pointers",
      "File I/O and Streams",
      "Dynamic Data Structures",
      "Object-Oriented Programming (OOP)",
      " Operator Overloading and Templates",
    ]
  },
  {
    heading:"Advanced of C & C++",
    topics:[
      "Concurrency and Multithreading",
      "Advanced Memory Management",
      "Meta programming and Templates",
      "Design Patterns and Advanced OOP",
      "Cross-Platform Development and Interfacing with Hardware",
    ]
  },
  {
    heading :"Technical Interview Preparation",
    topics:[
      "Master Core Concepts: Gain in-depth knowledge in Data Structures, Algorithms, OOP, and System Design.",
      "Coding Practice: Solve coding problems with real-time compilers to sharpen your problem-solving skills.",
      "Mock Interviews: Participate in mock technical interviews to simulate real interview environments.",
      "Company-Specific Patterns: Learn interview patterns and key topics for top tech companies like Google, Amazon, and Microsoft.",
    ]
},
{
  heading:"Soft Skills and HR Interview Training",
  topics:[
    "Behavioral Interview Techniques: Practice common behavioral questions using the STAR method.",
    "Communication Skills: Enhance your verbal and non-verbal communication through personalized feedback.",
    "Group Discussions and Case Studies: Improve teamwork and presentation skills with guided group discussions and case study exercises.",
    "HR Round Preparation: Get prepared for HR interviews by learning how to articulate your experiences, strengths, and career aspirations.",
  ]
}
];
export const DsaFullStack =[
  {
    heading :"Dsa FullStack Course ",
    topics:[
     "Foundation of Programming Efficiency",
     "Core Data Structures",
     "Advanced Algorithm Techniques",
     "Time and Space Complexity",
     "Real-world Problem Solving",
     "Interview Preparation"
    ]
  },
  {
    heading:"C & C++ Course",
    topics:[
      "Comprehensive Language Foundation",
      "Memory Management and Pointers",
      "Object-Oriented Programming (OOP) in C++",
      " Data Structures and Algorithms",
      "Advanced Features and Libraries",
      "Real-world Application & Interview Prep"
    ]
  },
  {
    heading:"Python Full Stack Development",
    topics:[
      "Comprehensive Python Programming",
      "Frontend Development",
      "Web Frameworks (Django/Flask)",
      "Database Management",
      "REST APIs and Web Services",
      "Deployment and DevOps"
    ]
  },
  {
    heading:"Java Full Stack Development",
    topics:[
      "Core Java Programming",
      "Frontend Technologies",
      "Java Backend Frameworks (Spring Boot)",
      "Database Management",
      " REST APIs and Microservices",
      "Deployment and Cloud Integration"
    ]
  },
  {
    heading:"Mern Full Stack Development",
     topics:[
      "MongoDB (Database Layer)",
      "Express.js (Backend Framework)",
      "React.js (Frontend Library)",
      "Node.js (Runtime Environment)",
      "Full Stack Integration",
      "API and RESTful Services"
     ]
  },
 
]


