export const jobData = [
  {
    title: "IT Project Bidder",
    experience: "6 months to 1+ years",
    skills: [
      "Technical Knowledge",
      "Project Management",
      "Communication Skills",
      "Problem-Solving Skills",
      "Business Acumen",
      "Industry Knowledge",
    ],
  },
  {
    title: "Data analyst Bidder",
    experience: "6 months to 1+ years",
    skills: [
      "Statistical Analysis",
      "Data Visualization",
      "Big Data Technologies",
      "Database Management",
      "Communication Skills",
    ],
  },
  {
    title: "Full Stack MERN Trainer",
    experience: "6 months to 1+ years",
    skills: ["Full Stack", "MongoDB", "Express Js", "React Js", "Node Js"],
  },
  {
    title: "Python Trainer",
    experience: "6 months to 1+ years",
    skills: [
      "Expertise in Python",
      "Hands-on Experience",
      "Problem-Solving Skills",
      "Interactive Teaching Techniques",
      "Debugging and Troubleshooting",
      "Continuous Learning",
    ],
  },
  {
    title: "Data analyst Trainer",
    experience: "6 months to 1+ years",
    skills: [
      "Statistical Analysis",
      "Data Visualization",
      "Big Data Technologies",
      "Database Management",
      "Expertise in Data Analysis",
    ],
  },
  {
    title: "Digital Marketing",
    experience: "Fresher",
    skills: [
      "Basic Marketing Knowledge",
      "Content Creation Skills",
      "Email Marketing",
      "SEO Basics",
      "Graphic Design Skills",
      "Creativity and Innovation",
    ],
  },
];
