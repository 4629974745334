import React from "react";
import { carouselItems } from "../../data/employeeNinternData";

const InternCard = () => {
  const imgPath = window.location.origin.toString();
  const interns = carouselItems;

  return (
    <div className="container">
      <div className="row justify-content-center">
        {interns.map((items, index) => (
          <div key={index} className="col-sm-12 col-md-4 col-lg-4 col-xl-4 mb-3">
            <div className="card">
              <img
                className="card-img-top"
                src={`${imgPath}/${items?.imgSrc}`}
                alt="Mindcoders Learner"
              />
              <div className="card-body text-center">
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    className="icon-img"
                    src={`${imgPath}/assets/img/learners of mindcoders/right.png`}
                    alt="right"
                  />
                  <h4 className="h4 card-title text-color-navy mx-2">
                    {items?.name}
                  </h4>
                  <img
                    className="icon-img"
                    src={`${imgPath}/assets/img/learners of mindcoders/left.png`}
                    alt="left"
                  />
                </div>
                <p className="card-text text-info mt-2">
                  <strong>{items?.position}</strong>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InternCard;
