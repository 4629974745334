export { default as HOME_PATH } from "../pages/HomePage";
export { default as ABOUT_PATH } from "../pages/AboutUsPage";
export { default as COURSES_PATH } from "../pages/CoursePage";
export { default as PLACEMENTS_PATH } from "../pages/PlacementPage";
export { default as WORKSHOP_PATH } from "../pages/WorkshopPage";
export { default as BLOGS_PATH } from "../pages/BlogPage";
export { default as BLOG_DETAILED_PATH } from "../pages/blogs/BlogDetailedPage";
export { default as CONTACT_PATH } from "../pages/ContactUsPage";
export { default as CAREER_PATH } from "../pages/CareerPage";
export { default as JOBS_PATH } from "../pages/JobsPage";

export { default as COURSE_DSA } from "../pages/courses/DsaPage";
export { default as COURSE_PYTHON } from "../pages/courses/PythonProgrammingPage";
export { default as COURSE_PYTHON_FULLSTACK } from "../pages/courses/PythonFullstackPage";
export { default as COURSE_JAVA } from "../pages/courses/JavaProgrammingPage";
export { default as COURSE_JAVA_FULLSTACK } from "../pages/courses/JavaFullstackPage";
export { default as COURSE_FRONEND } from "../pages/courses/FrontEndPage";
export { default as COURSE_BACKEND } from "../pages/courses/BackendPage";
export {default as COURSE_REACT}  from "../pages/courses/ReactJsPage";
export {default as COURSE_DIGITALMARKETING}  from "../pages/courses/DigitalMarketingPage";
export {default as COURSE_DATAANALITICS}  from "../pages/courses/DataAnaliticsPage";
export {default as COURSE_DATA_SCIENCE}  from "../pages/courses/DataSciencePage";
export {default as COURSE_MERN}  from "../pages/courses/MERNPage";
export {default as COURSE_MASTER_DSA}  from "../pages/courses/MasterDSA";
export {default as COURSE_DSA_ADDON_FULLSTACK}  from "../pages/courses/DSAaddOnFullstack";

export { default as TERMS_CONDITIONS_PATH } from "../pages/TermsAndConditions";
export { default as LIFE_AT_MINDCODERS } from "../pages/LifeAtMindcoders";
export { default as PRIVACY_POLICY_PATH } from "../pages/PrivacyAndPolicy";
export { default as INTERNSHIP } from "../pages/Internship";

export { default as SERVICES } from "../pages/Services";